import './App.css';
import Navbar from './Components/Navbar'
import Footer from './Components/Footer'
import Home from './Components/Home/Home'
import CookiesBtn from './Components/CookiesBtn';


const App = () => {
  return (
    <>
     <Navbar />
     <Home/>
     <Footer/>
     <br/>
     <CookiesBtn/>
    </>
  );
}

export default App;
