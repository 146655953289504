import React from 'react'
import './Header.css'


const Header = () => {
  return (
    <div className="background">
      <div className="container">
        <div className="row">
        <div className="pt-5 mt-5">
            <i className="fa fa-angle-right fa-3x m-0 p-0 font-weight-bold " style={{color:'#D47DB2',}}></i><i class="fa fa-angle-right fa-3x m-0 p-0 font-weight-bold" style={{color:'#D47DB2'}}></i><i class="fa fa-angle-right fa-3x m-0 p-0 font-weight-bold" style={{color:'#D47DB2'}}></i>
        </div>
        <div className="col-10 col-sm-10 col-md-8 col-lg-6 col-xl-6 pt-5 m-0 mt-5 mb-5">
            <h1 className="sm-head-1">
            Soluções de Atendimento Humanizado em saúde</h1>
            <p className="mt-3 mb-5  para-style">
            Acreditamos que empatia no atendimento humano é fundamental para colocar o paciente e suas necessidades no centro. A automação agiliza o
            atendimento, a tecnologia facilita a comunicação e organiza informações
            úteis, mas é a interação humana com respeito e carinho que garantem soluções efetivas e qualidade na experiência do atendimento ao paciente.
          </p>
      <br/>
      <br/>
      </div>
        </div>
      </div>  
    </div>
  )
}


export default Header;