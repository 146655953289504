import React from 'react'
import Logo from '../images/logo.png'

const Navbar = () => {
  return (
    <>
    <nav class="navbar navbar-expand-lg navbar-light " 
       style={{backgroundColor:'#d47db2'}}>
      <a class="navbar-brand">
      <img src={Logo} class="img-fluid d-inline-block align-top" alt="" style={{height: "80px"}}/></a>
      <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
        <span class="navbar-toggler-icon"></span>
      </button>

  <div class="collapse navbar-collapse" id="navbarSupportedContent">
    <ul class="navbar-nav ml-auto ">
     
      <li class="nav-item my-auto">
        <a href="politica_privacidade.pdf" target="_blank" className="nav-link  text-white pr-md-5 p-0"><p className="mt-3 text-white">Política de privacidade</p></a>
      </li>
      <li class="nav-item my-auto">
        <a href="mailto:contato@esah.com.br" className="nav-link text-white pr-md-5 p-0">
          <p className="mt-md-3 mt-0 text-white">contato@esah.com.br</p></a>
      </li>
      <li class="nav-item my-auto pr-4">
        <a className="nav-link font-weight-bold text-white pr-5">
        <i class="fa fa-angle-right fa-3x m-0 p-0 font-weight-bold"></i><i class="fa fa-angle-right fa-3x m-0 p-0 font-weight-bold"></i><i class="fa fa-angle-right fa-3x m-0 p-0 font-weight-bold"></i>
        </a>
      </li>
    </ul>
   
  </div>
</nav>

    </>
  )
}


export default Navbar;