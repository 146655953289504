import React from 'react'

// components

import Header from '../Header/Header'
import CookiesBtn from '../CookiesBtn'
import Banner from '../Banner/Banner'
import Cards from '../Cards'
import Sub_Card from '../Sub_Card'
import Main_Section from '../Main_Section'

// css file
import './Home.css'

const Home = () => {
  return (
    <>
    <Header/>
    {/* <CookiesBtn/> */}
    <Main_Section/>
    <Banner/>
    <Sub_Card/>
    <Cards/>
   </>
  )
}


export default Home;