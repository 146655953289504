import React from 'react'

// images
import image05 from '../images/sms.jpg'
import image06 from '../images/aplicativo.jpg'
import image07 from '../images/ura.jpg'


const Sub_Card = () => {
  return (   
    <div className="mt-5 container-fluid pl-md-5">
      <p className="pb-4 w-75 pl-md-5"> Para que todas as soluções de atendimento sejam efetivas, desenhamos réguas de relacionamento totalmente customizadas no canal de atendimento de sua preferência, tornando ainda maior a adesão ao tratamento de cada paciente.
      </p>
    <div className="row pl-md-5 pl-0 pl-sm-0">
    
       <div className="col-12 col-sm-12 col-md-4 col-lg-4 col-xl-4">
         <div className="row">

         <div className="col-1 col-sm-1 col-md-1 d-xl-block d-none">
            <div style={{borderLeft:'2px solid #D47DB2', height:'240px'}}></div>
        </div>
         
         <div className="col-11 col-sm-10 col-md-11">
         <img src={image05} className="img-fluid w-50 ml-4 ml-sm-4 ml-md-0" alt="sms"/>
              <div className="ml-4 ml-sm-4 ml-md-0">
                <h6 className="sm-head mt-4 font-weight-bold">SMS</h6>
                <p className="w-75">Lembretes e novidades para o tratamento do seu paciente.</p>
              </div>
         </div>
         </div>  
       </div>
       <div className="col-12 col-sm-12 col-md-4 ">
              <img src={image06} className="img-fluid w-50 ml-4 ml-sm-4 ml-md-0" alt="sms"/>
          <div className="ml-4 ml-sm-4 ml-md-0">
          <h6 className="sm-head mt-4 font-weight-bold">Aplicativo</h6>
          <p className="w-75">Suporte, informações e novidades sobre o programa de acesso e adesão ao tratamento.</p>
          </div>
       </div>

       <div className="col-12 col-sm-12 col-md-4 ">
         <img src={image07} className="img-fluid w-50 ml-4 ml-sm-4 ml-md-0" alt="sms"/>
         <div className="ml-4 ml-sm-4 ml-md-0">
            <h6 className="sm-head mt-4 font-weight-bold">URA Inteligente</h6>
            <p className="w-75">Excelência no atendimento e cumprimento de SLA. Relatórios em tempo real sobre seu atendimento.</p>
        </div>
       </div>
    </div>
</div>

  )
}

export default Sub_Card;