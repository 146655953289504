import React from 'react'
import './Home/Home.css'

const CookiesBtn = () => {
  return (
    <div className="fixed-bottom p-3 container-fluid lead-0 mt-4 cookie-notice" style={{backgroundColor:'#fff', color:'#000',}}>   
          <div class="row d-flex justify-content-center">
              <div class="col-12 col-sm-12 col-md-8 col-lg-8 col-xl-8 m-md-0 p-md-0 ">
              <p className="" style={{fontSize:'16px'}}>Este site usa cookies e dados pessoais de acordo com os nossos Termos de Uso e Políticas de Cookies e, ao continuar navegando neste site, você declara estar ciente dessas condições. <a href="#">Termos de Uso e Políticas de Cookies</a> 
              </p>
              </div>
              <div class="col-12 col-sm-12 col-md-2 col-lg-2 col-xl-2 mt-2 ">
              <button type="submit" className="btn btn-primary btn-block accept-cookies" name="sf-submit" >Aceito</button>
              </div>
          </div>
        
      </div>
  )
}


export default CookiesBtn;