import React from 'react'
import '../App.css'

// images

import image01 from '../images/01.jpg'
import arrowImage from '../images/arrowImage.jpg'

const Main_Section = () => {
  return (
    <div className="container-fluid mt-3">
      <div className="row">
      
        <div className="col-12 col-sm-12 col-md-8 mt-5">
          <div className="row">
          <div className="offset-0 offset-sm-0 offset-md-1">

          </div>
              <div className="col-2 col-sm-2 col-md-1 ml-md-3 ml-0 ml-sm-0" style={{borderLeft:'2px solid #D47DB2', height:'280px'}} >
             <img src={arrowImage} className="img-fluid"/> 
              </div>
            <div className="col-10 col-sm-10 col-md-9 p-0 m-0" >
                <div className="head-div">PLATAFORMAS DE</div>
                <div className="head-div"> ATENDIMENTO DIGITAL</div>
                <div className="head-div">ESPECIALIZADAS EM SAÚDE</div>
                <p className="mt-3">
                Desenvolvemos soluções de atendimento aliando a tecnologia moderna ao
                cuidado humano. Com plataformas digitais e customizáveis, além de uma equipe multidisciplinar em saúde, aproximamos ainda mais o seu contato com o paciente nos canais mais relevantes e atuais.
                </p>
              </div>
          </div>
          
        </div>
        <div className="col-md-4 p-0 m-0" >
        <img src={image01} className="img-fluid"  alt="" />
        </div>
      </div>
</div>
  )
}


export default Main_Section