import React from 'react'

import './Home/Home.css'

// image

import Footerimage from '../images/003.png'
import img_arrow from '../images/img_arrow.jpg'

const Footer = () => {
  return (
    <>
     <div className="container-fluid mt-5">
       <div className="row">
         <div className="col-12 col-sm-12 col-md-3 m-0 p-0">
         <img src={Footerimage} className="img-fluid" alt="" />
         </div>
         <div className="col-md-7 offset-md-1 my-4">
           <div className="row">
             <div className="col-2 col-sm-2 col-md-2">
               <img src={img_arrow} className="img-fluid" />
             </div>
             <div className="col-9 col-sm-9 col-md-9 p-0">
                <h1 className="foot-head-1" style={{color:'#737373'}}> FALE COM NOSSOS ESPECIALISTAS</h1>
             </div>
           </div>      
           <div className="row pl-md-5 mt-2 ml-md-1">
             <div className="col-12 col-sm-12 col-md-6 offset-md-1 offset-0 offset-sm-0 col-lg-6 col-xl-6 p-5" style={{borderLeft:'2px solid #D47DB2', height:'240px'}}>
                 <h5 className="font-weight-bold"> E-mail</h5>
                 <a href="mailto:contato@esah.com.br">
                  <p>contato@esah.com.br</p>
                 </a>
             </div>
             <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-4 mt-xl-4 ml-lg-5 ml-xl-5 pl-md-5 pl-5 pl-sm-5 contact">
                <a href="politica_privacidade.pdf" target="_blank">
                  <p>Política de privacidade</p>
                </a> 
                <a href="politica_cookies.pdf" target="_blank">
                  <p>Política de cookies</p> 
                </a>
                <a href="mailto:dpo@esah.com.br">
                    <p>dpo@esah.com.br</p>
                </a>
             </div>
           </div>
      </div>
       </div>
     </div>
     <div class="footer">
   <div class="container">
     <div class="row">
       <div class="col text-center my-1">
        <p>eSAH - Soluções de Atendimento Humanizado em Saúde.<br/>
        CSAT Relações e Atendimentos Ltda - CNPJ 41.097.862/0001-61</p>
       </div>
     </div>
   </div>
 </div>

    </>
  )
}


export default Footer;