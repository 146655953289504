import React from 'react'
import './Banner.css'


const Banner = () => {
  return (
    <div className="container-fluid img-back p-5 my-5">
     <div className="row pl-md-5">
       <div className="col-12 col-sm-12 col-md-10  pl-md-3">
       {/* <span className="head-xs1 p-md-3 text-center font-weight-bold"> ATENDIMENTO DIGITAL</span> */}
       <div class="head-xs1 font-weight-bold p-2">ATENDIMENTO DIGITAL</div> 
      </div>
      <div className="col-12 col-sm-12 col-md-2 col-lg-2 col-xl-2 pl-md-5 text-white font-weight-bold d-lg-block d-none">
       
        <i class="fa fa-angle-right fa-4x m-0 p-0 pl-0 pl-sm-0 pl-md-5 pl-xl-5 pl-lg-0 font-weight-bold"></i><i class="fa fa-angle-right fa-4x m-0 p-0 font-weight-bold"></i><i class="fa fa-angle-right fa-4x m-0 p-0 font-weight-bold"></i>
        
      </div>
    </div>
  

  </div>
  )
}

export default Banner;
