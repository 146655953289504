import React from 'react'
import '../Components/Home/Home.css'

//images
import image02 from '../images/002.png'
import image03 from '../images/004.png'
import image04 from '../images/005.png'

const Cards = () => {
  return (
    <>
      <div className="mt-5 container-fluid pl-md-5">
        <div className="pl-md-5">
          <h4 class="head-style">ATENDIMENTO HUMANIZADO</h4>
        </div>

        <div className="row mt-5 pl-md-5">
          <div className="col-12 col-sm-12 col-md-4">
            <div className="row">
              <div className="col-1 col-sm-1 col-md-1">
                <div style={{ borderLeft: '2px solid #D47DB2', height: '190px' }}></div>
              </div>
              <div className="col-11 col-sm-11 col-md-10 p-md-1 p-lg-1 p-xl-1">
                <img src={image02} alt="sms" className="img-fluid mb-3 " />
                <h5 className="sm-head font-weight-bold mt-4">WhatsApp</h5>
                <p className="w-75">Um dos principais canais de contato com o seu paciente.</p>
              </div>
            </div>
          </div>
          <div className="col-12 col-sm-12 col-md-4">
            <div className="row">
              <div className="col-1 col-sm-1 col-md-1">
                <div style={{ borderLeft: '2px solid #D47DB2', height: '190px' }}></div>
              </div>
              <div className="col-11 col-sm-11 col-md-10 p-md-1 p-lg-1 p-xl-1">
                <img src={image03} alt="atendimento" className="img-fluid mb-3" />
                <h5 className="sm-head font-weight-bold mt-4">Atendimento Telefônico</h5>
                <p className="w-75">Equipe Multidisciplinar de profissionais da saúde para suporte em tratamentos.</p>
              </div>
            </div>
          </div>
          <div className="col-12 col-sm-12 col-md-4">
            <div className="row">
              <div className="col-1 col-sm-1 col-md-1">
                <div style={{ borderLeft: '2px solid #D47DB2', height: '190px' }}></div>
              </div>
              <div className="col-11 col-sm-11 col-md-10 p-md-1 p-lg-1 p-xl-1">
                <img src={image04} alt="Chatbot" className="img-fluid mb-3" />
                <h5 className="sm-head font-weight-bold mt-4">Chatbot</h5>
                <p className="w-75">E inteligência artificial. Agilidade para sua rotina de atendimentos.</p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="mt-5 container-fluid pl-md-5">
        <div className="pl-md-5">
          <h4 class="head-style">PROJETOS</h4>
        </div>

        <div className="row mt-5 pl-md-5">
          <p className="pb-4 w-75 pl-md-5"> 
            - Desenvolvemos o projeto <b>Vivendo MPS e CLN2</b>, de forma personalizada para o suporte a pacientes de doenças raras com mucopolissacaridose. <br></br>
            A solução bot, permite o acompanhamento em tempo real da equipe multidisciplinar especializada, com acolhimento e aderência ao tratamento.
          </p> 
          
          <p className="pb-4 w-75 pl-md-5"> 
          - O <b>Programa NovoDia</b> oferece informações educacionais, suporte de profissionais de saúde e ainda descontos na compra de medicamentos, proporcionando maior acesso e adesão de pacientes diabéticos e obesos à seus tratamentos.
          </p>
          
          <p className="pb-4 w-75 pl-md-5"> 
          - <b>Programa Horizontes</b>: O nanismo é uma condição genética que causa o crescimento desproporcional entre os membros (pernas e braços) e o tronco, resultando principalmente em pessoas com estatura abaixo da média em relação à população da mesma idade e sexo.<br/>
          Acondroplasia é o nome de uma doença rara, popularmente conhecida como nanismo, as  causas da Acondroplasia podem ser genéticas e estão relacionadas com a mutação de um gene, que tem relação com o crescimento dos ossos.<br />
          É importante mencionar que o acesso ao diagnóstico de Acondroplasia, acompanhamento da doença, são deficientes no Brasil, bem como acesso a informações sobre a doença, inclusão e direitos das pessoas com a patologia.<br />
          O programa foi criado para apoiar pessoas e familiares com diagnósticos de Acondroplasia e prescrição do medicamento Voxzogo.
          </p>
          
          <p className="pb-4 w-75 pl-md-5"> 
          - <b>Programa Sempre em Frente</b>: O programa Sempre em Frente foi criado para dar suporte ao paciente ao longo do tratamento com o medicamento Cimzia (certolizumabe pegol) e ao paciente que irá realizar os exames disponíveis conforme elegibilidade.
          </p>

          <p className="pb-4 w-75 pl-md-5">
            - <b>Programa ID</b>: Sabemos que receber o diagnóstico é um momento difícil para o paciente e para sua família, mas acreditamos que o acesso à informação pode ajudar a desmistificar a doença. Em alguns tipos de câncer, já podem ser identificadas mutações específicas, através de testes diagnósticos, que podem levar a um tratamento mais personalizado. O Programa ID surgiu para ser um facilitador dessa jornada e fornecer aos médicos e seus pacientes apoio ao diagnóstico de diferentes patologias, através dos exames como EGFR, T790M, HDR, HRR e PD-L1
          </p>

          <p className="pb-4 w-75 pl-md-5">
            - <b>Alô Beneficiário</b>: Mais do que um simples benefício em medicamentos; Cuidado completo com a saúde dos nossos beneficiários. Somos especialistas em benefícios de medicamentos e reunimos uma ampla gama de programas. assistências e serviços tendo como principal atributo, o acesso ao medicamento e engajamento do usuário. Este canal foi criado para dar suporte aos beneficiários no esclarecimento de dúvidas sobre o seu benefício de medicamentos.
          </p>

          <p className="pb-4 w-75 pl-md-5">
            - <b>Atendimento Redes</b>: O Atendimento Redes é o canal de atendimento à rede parceira epharma.
          </p>

          <p className="pb-4 w-75 pl-md-5">
            - <b>Alô Paciente</b>: Este canal foi criado para dar todo suporte quanto ao tratamento, seja ele medicamentoso ou não, aos pacientes inscritos em programas epharma. Somos especialistas em benefícios de medicamentos e reunimos uma ampla gama de programas. assistências e serviços tendo como principal atributo, o acesso ao medicamento e engajamento do usuário
          </p>

          <p className="pb-4 w-75 pl-md-5">
            - <b>Seja epharmer</b>: Fale com a Fabi sobre o processo seletivo, status da sua candidatura e saiba mais sobre a epharma
          </p>

          <p className="pb-4 w-75 pl-md-5">
            - <b>Programa União Com Você</b>: A União pode fazer a vida ficar ainda mais especial! Nós da União Química valorizamos muito o que as pessoas tem de mais rico na vida: saúde para desfrutar de forma plena tudo que merece. Pensando nisso criamos o Programa União com Você para disponibilizar diversos benefícios e ajudar a levar bem-estar para a vida dos nossos pacientes e consumidores.
          </p>

          <p className="pb-4 w-75 pl-md-5">
            - <b>Alô Cliente</b>: Canal dedicado aos nossos clientes para esclarecimentos de dúvidas, reclamações, solicitações e elogios.
          </p>

          <p className="pb-4 w-75 pl-md-5">
            - <b>Programa Cristália Saúde</b>: Um Programa que oferece aos pacientes informações educacionais e benefícios nas compras dos medicamentos do Programa Cristália Saúde.
          </p>
        </div>
      </div>
    </>
  )
}


export default Cards;